import React, { useEffect, useState } from 'react'
import styles from './AccordionSection.module.scss'
import { ChevronArrowIcon, HelpModal } from 'components'
import clsx from 'clsx'

const AccordionSection = ({
  className,
  titleClass = '',
  title = '',
  description = '',
  opened: initOpened = false,
  children,
  contentHeightScale = 1,
  isDisabledToggle = false,
  onActionToOpen = () => null,
  onActionToClose = () => null,
  help = false,
  helpChildren = <></>,
}) => {
  const [isOpened, setOpened] = useState(false)

  useEffect(() => setOpened(initOpened), [initOpened])

  const handleClickClose = (e) => {
    e.preventDefault()

    if (isDisabledToggle) return

    setOpened((isOpened) => {
      !isOpened ? onActionToOpen() : onActionToClose()
      return !isOpened
    })
  }

  return (
    <div className={clsx(className, styles.section)}>
      <div className={styles.section__header}>
        <span className={clsx(titleClass, styles.section__title)}>{title}</span>
        <span className={styles.section__description}>{description}</span>
        {help && (
          <HelpModal className={styles.helpModal} help={help}>
            {helpChildren}
          </HelpModal>
        )}
        {isDisabledToggle ? null : (
          <button
            type="button"
            className={styles.section__close}
            onClick={handleClickClose}
          >
            <ChevronArrowIcon
              className={clsx(styles.icon, isOpened && styles['icon--opened'])}
            />
          </button>
        )}
      </div>

      <div
        className={clsx(
          styles.section__content,
          isOpened && styles[`section__content--opened`],
          isOpened && styles[`section__content--opened--${contentHeightScale}x`]
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default AccordionSection
