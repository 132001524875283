import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import {
  Breadcrumb,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  PlusCircleIcon,
  RemoveIcon,
  CSVIcon,
  TextInput,
  Select,
  AddressInput,
  TextArea,
  ColorPickerModal,
  AddRoleModal,
  HelpModal,
} from 'components'
import { useStateValue } from 'state'
import styles from './Edit.module.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate as useHistory } from 'react-router-dom'
import _ from 'lodash'
import { useNavigate } from 'hooks'
import {
  PERSON_TYPE,
  PATH,
  REWARD__AR__CURRENCY_OPTIONS,
} from 'utils/constants'
import Help from 'components/Help'
import { oneMbSizeLimit } from 'utils'
import SampleCsv from 'assets/customers.csv'

const Edit = () => {
  const history = useHistory()
  const {
    action: {
      getCamp,
      updateCamp,
      uploadCampCsvFile,
      removeCampCsvFile,
      removeCampRole,
      addCampRole,
    },
    state: {
      auth: { user },
      help,
    },
  } = useStateValue()
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [colorPickerOptions, setColorPickerOptions] = useState({})
  const [openAddRole, setOpenAddRole] = useState(false)
  const logoFileRef = useRef(null)
  const customerCsvFileRef = useRef(null)
  const counselorCsvFileRef = useRef(null)
  const navigate = useNavigate()
  const { id } = useParams()
  const [camp, setCamp] = useState({
    name: '',
    website: '',
    email: '',
    phone: '',
    mission: '',
    logo: null,
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    currency: '$',
    primaryColor: '#5992FF',
    secondaryColor: '#CCDDFF',
    buttonColor: '#EBF2FF',
    roles: [],
    customerCsvFiles: [],
    counselorCsvFiles: [],
  })

  // eslint-disable-next-line
  useEffect(
    () => (id ? getCamp(id, setCamp) : navigate({ pathname: PATH.CAMPS })),
    [id]
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: camp,
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter camp name.'),
      website: Yup.string()
        .required('Please enter camp website.')
        .url('Please enter a valid website.'),
      email: Yup.string()
        .required('Please enter camp email.')
        .email('Please enter a valid email.'),
      phone: Yup.string().nullable(),
      address_1: Yup.string().required('Please enter your address.'),
      city: Yup.string().required('Please enter your city.'),
      state: Yup.string().required('Please select your state.'),
      country: Yup.string().required('Please select your country/region.'),
      zipCode: Yup.string().required('Please enter your zipcode.'),
    }),
    onSubmit: (data) => {
      const campDetails = {}

      Object.keys(data).forEach((key) => {
        if (formik.initialValues[key] !== data[key]) {
          key === 'currency' && console.log('new currency ', data[key])
          campDetails[key] = data[key]
        }
      })
      //Object.keys(data).forEach(key => formik.initialValues[key] !== data[key] ? campDetails[key] = data[key] : null);

      updateCamp(id, campDetails, () => navigate({ pathname: PATH.CAMPS }))
    },
  })

  const handleAddLogo = ({ target: { files } }) => {
    if (!files[0]) return

    if (oneMbSizeLimit(files[0])) {
      formik.setFieldValue('logo', files[0])
    }
  }

  const handleClickLogoFileUploader = (e) => {
    e?.preventDefault()

    logoFileRef.current.click()
  }

  const handleRemoveCustomerCsvFile = (csvFile) =>
    removeCampCsvFile(id, csvFile, () =>
      formik.setFieldValue(
        'customerCsvFiles',
        _.difference(formik.values.customerCsvFiles, [csvFile])
      )
    )

  const handleRemoveRole = (user, index) =>
    removeCampRole(id, user.userId, () =>
      formik.setFieldValue(
        'roles',
        formik.values.roles.filter((role, _rIndex) => _rIndex + 1 !== index)
      )
    )

  const handleAddRole = (role) => {
    addCampRole(id, role, (addedRole) => {
      formik.setFieldValue('roles', _.concat(formik.values.roles, addedRole))
    })
  }

  const handleAddCustomerCsvFile = (e) =>
    uploadCampCsvFile(id, e.target.files[0], PERSON_TYPE.CUSTOMER, () => {
      formik.setFieldValue(
        'customerCsvFiles',
        formik.values.customerCsvFiles.concat(e.target.files[0].name)
      )
      e.target.value = null
    })

  const handleClickCustomerCsvFileUploader = (e) => {
    e.preventDefault()

    customerCsvFileRef.current.click()
  }

  const handleRemoveCounselorCsvFile = (csvFile) =>
    removeCampCsvFile(id, csvFile, () =>
      formik.setFieldValue(
        'counselorCsvFiles',
        _.difference(formik.values.counselorCsvFiles, [csvFile])
      )
    )

  const handleAddCounselorCsvFile = (e) =>
    uploadCampCsvFile(id, e.target.files[0], PERSON_TYPE.COUNSELOR, () => {
      formik.setFieldValue(
        'counselorCsvFiles',
        formik.values.counselorCsvFiles.concat(e.target.files[0].name)
      )
      e.target.value = null
    })

  const handleClickCounselorCsvFileUploader = (e) => {
    e.preventDefault()

    counselorCsvFileRef.current.click()
  }

  const handleEditPrimaryColor = (e) => {
    e.preventDefault()

    setColorPickerOptions({
      title: 'Primary Color',
      color: formik.values.primaryColor,
      onUpdateColor: (color) => formik.setFieldValue('primaryColor', color),
    })

    setOpenColorPicker(true)
  }

  const handleEditSecondaryColor = (e) => {
    e.preventDefault()

    setColorPickerOptions({
      title: 'Secondary Color',
      color: formik.values.secondaryColor,
      onUpdateColor: (color) => formik.setFieldValue('secondaryColor', color),
    })

    setOpenColorPicker(true)
  }

  const handleEditButtonColor = (e) => {
    e.preventDefault()

    setColorPickerOptions({
      title: 'Button Color',
      color: formik.values.buttonColor,
      onUpdateColor: (color) => formik.setFieldValue('buttonColor', color),
    })

    setOpenColorPicker(true)
  }

  return (
    <>
      <Breadcrumb
        items={[
          { to: PATH.CAMPS, text: 'My Camps' },
          { to: `${PATH.CAMPS__EDIT}/${id}`, text: camp.name },
        ]}
      />

      <div className={styles.page}>
        <form className={styles.form}>
          <fieldset>
            <legend className={styles.form__legend}>
              Camp Details
              {help.find(
                (h) => h.tag === 'my_camps_details_overview'
              ) && (
                <HelpModal
                  help={help.find(
                    (h) => h.tag === 'my_camps_details_overview'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'my_camps_details_overview'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </legend>

            <div className={clsx(styles.form__row, styles.form__row__reverse)}>
              <div className={styles.form__col}>
                <TextInput
                  label="Camp Name"
                  value={formik.values.name}
                  onChange={(name) => formik.setFieldValue('name', name)}
                  {...(formik.touched.name && formik.errors.name
                    ? { isError: true, captionText: formik.errors.name }
                    : {})}
                  requiredShown
                  help={help.find(
                    (h) => h.tag === 'my_camps_camp_details_name'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find((h) => h.tag === 'my_camps_camp_details_name')
                          ?.text
                      }
                    </p>
                  }
                />

                <TextInput
                  label="Camp Website"
                  placeholder="http://"
                  value={formik.values.website}
                  onChange={(website) =>
                    formik.setFieldValue('website', website)
                  }
                  {...(formik.touched.website && formik.errors.website
                    ? { isError: true, captionText: formik.errors.website }
                    : {})}
                  help={help.find(
                    (h) => h.tag === 'my_camps_camp_details_website'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find(
                          (h) => h.tag === 'my_camps_camp_details_website'
                        )?.text
                      }
                    </p>
                  }
                />

                <TextInput
                  label="Camp Email"
                  value={formik.values.email}
                  onChange={(email) => formik.setFieldValue('email', email)}
                  {...(formik.touched.email && formik.errors.email
                    ? { isError: true, captionText: formik.errors.email }
                    : {})}
                  requiredShown
                  help={help.find(
                    (h) => h.tag === 'my_camps_camp_details_email'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find(
                          (h) => h.tag === 'my_camps_camp_details_email'
                        )?.text
                      }
                    </p>
                  }
                />

                <TextInput
                  label="Phone"
                  value={formik.values.phone}
                  onChange={(phone) => formik.setFieldValue('phone', phone)}
                  {...(formik.touched.phone && formik.errors.phone
                    ? { isError: true, captionText: formik.errors.phone }
                    : {})}
                  help={help.find(
                    (h) => h.tag === 'my_camps_camp_details_phone'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find(
                          (h) => h.tag === 'my_camps_camp_details_phone'
                        )?.text
                      }
                    </p>
                  }
                />

                <Select
                  label="Currency"
                  options={REWARD__AR__CURRENCY_OPTIONS}
                  value={formik.values.currency}
                  onChange={(currency) =>
                    formik.setFieldValue('currency', currency)
                  }
                  help={help.find(
                    (h) => h.tag === 'my_camps_camp_details_currency'
                  )}
                  helpChildren={
                    <p>
                      {
                        help.find(
                          (h) => h.tag === 'my_camps_camp_details_currency'
                        )?.text
                      }
                    </p>
                  }
                />
              </div>

              <div className={styles.form__col}>
                <div
                  className={clsx(
                    styles.form__add_logo,
                    formik.touched.logo &&
                      formik.errors.logo &&
                      styles['form__add_logo--error'],
                    !formik.values.logo && styles.form__add_logo__edit
                  )}
                >
                  <input
                    ref={logoFileRef}
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleAddLogo}
                  />

                  {formik.values.logo ? (
                    <>
                      <img
                        className={styles.form__add_logo__preview}
                        src={
                          typeof formik.values.logo === 'string'
                            ? formik.values.logo
                            : URL.createObjectURL(formik.values.logo)
                        }
                        alt={formik.values.logo.name}
                      />
                    </>
                  ) : null}

                  {formik.values.logo ? (
                    <>
                      <TertiaryButton
                        className={styles.form__add_logo__change}
                        label="CHANGE LOGO"
                        onClick={handleClickLogoFileUploader}
                      />
                      <p className={styles.form__add_logo__name}>
                        (Image max size - 1mb)
                      </p>
                    </>
                  ) : (
                    <button
                      type="button"
                      className={styles.form__add_logo__button}
                      onClick={handleClickLogoFileUploader}
                    >
                      <PlusCircleIcon className={styles.form__add_logo__icon} />
                      <p>Add logo</p>
                      <p>
                        svg, png <br />
                        Image max size - 1mb
                      </p>
                    </button>
                  )}

                  {formik.touched.logo && formik.errors.logo ? (
                    <span className={styles.form__add_logo__caption}>
                      {formik.errors.logo}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className={styles.form__row}>
              <TextArea
                label="Our Mission"
                placeholder="Text here"
                value={formik.values.mission || ''}
                onChange={(mission) => formik.setFieldValue('mission', mission)}
                help={help.find(
                  (h) => h.tag === 'my_camps_camp_details_mission'
                )}
                helpChildren={
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'my_camps_camp_details_mission'
                      )?.text
                    }
                  </p>
                }
              />
            </div>

            <div className={styles.form__row}>
              <AddressInput
                label="Camp Address"
                value={`${formik.values.address_1}, ${formik.values.city}, ${formik.values.state} ${formik.values.zipCode}, ${formik.values.country}`}
                onChange={({ address_1, city, state, zipCode, country }) => {
                  formik.setFieldValue('address_1', address_1)
                  formik.setFieldValue('city', city)
                  formik.setFieldValue('state', state)
                  formik.setFieldValue('zipCode', zipCode)
                  formik.setFieldValue('country', country)
                }}
                {...(formik.touched.country && formik.errors.country
                  ? { isError: true, captionText: formik.errors.country }
                  : {})}
                {...(formik.touched.zipCode && formik.errors.zipCode
                  ? { isError: true, captionText: formik.errors.zipCode }
                  : {})}
                {...(formik.touched.state && formik.errors.state
                  ? { isError: true, captionText: formik.errors.state }
                  : {})}
                {...(formik.touched.city && formik.errors.city
                  ? { isError: true, captionText: formik.errors.city }
                  : {})}
                {...(formik.touched.address_1 && formik.errors.address_1
                  ? { isError: true, captionText: formik.errors.address_1 }
                  : {})}
                help={help.find(
                  (h) => h.tag === 'my_camps_camp_details_address'
                )}
                helpChildren={
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'my_camps_camp_details_address'
                      )?.text
                    }
                  </p>
                }
              />
            </div>
          </fieldset>

          <fieldset>
            <legend className={styles.form__legend}>
              Camp Color Palette
              {help.find(
                (h) => h.tag === 'my_camps_camp_details_camp_colors'
              ) && (
                <HelpModal
                  help={help.find(
                    (h) => h.tag === 'my_camps_camp_details_camp_colors'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'my_camps_camp_details_camp_colors'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </legend>
            <div
              className={clsx(
                styles.form__row,
                styles.form__row__color_palette
              )}
            >
              <div className={styles.form__color_picker__wrapper}>
                <p className={styles.form__color_picker__label}>Primary</p>
                <div className={styles.form__color_picker}>
                  <div
                    className={styles.form__color_picker__preview}
                    style={{ backgroundColor: formik.values.primaryColor }}
                    onClick={handleEditPrimaryColor}
                  />

                  <div className={styles.form__color_picker__text}>
                    <p className={styles.form__color_picker__color}>
                      {formik.values.primaryColor}
                    </p>
                    <button type="button" onClick={handleEditPrimaryColor}>
                      Edit
                    </button>
                  </div>
                </div>
              </div>

              <div className={styles.form__color_picker__wrapper}>
                <p className={styles.form__color_picker__label}>Secondary</p>
                <div className={styles.form__color_picker}>
                  <div
                    className={styles.form__color_picker__preview}
                    style={{ backgroundColor: formik.values.secondaryColor }}
                    onClick={handleEditSecondaryColor}
                  />

                  <div className={styles.form__color_picker__text}>
                    <p className={styles.form__color_picker__color}>
                      {formik.values.secondaryColor}
                    </p>
                    <button type="button" onClick={handleEditSecondaryColor}>
                      Edit
                    </button>
                  </div>
                </div>
              </div>

              {/* <div className={styles.form__color_picker__wrapper}>
                <p className={styles.form__color_picker__label}>Button</p>
                <div className={styles.form__color_picker}>
                  <div
                    className={styles.form__color_picker__preview}
                    style={{ backgroundColor: formik.values.buttonColor }}
                    onClick={handleEditButtonColor}
                  />

                  <div className={styles.form__color_picker__text}>
                    <p className={styles.form__color_picker__color}>
                      {formik.values.buttonColor}
                    </p>
                    <button type="button" onClick={handleEditButtonColor}>
                      Edit
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </fieldset>

          {/* <fieldset> */}
          {/*   <legend className={styles.form__legend}>Camp Seasons</legend> */}

          {/*   <div className={clsx(styles.form__row, styles.form__seasons)}> */}
          {/*     <TagInput */}
          {/*       tags={formik.values.seasons} */}
          {/*       onChange={tags => formik.setFieldValue('seasons', tags)} */}
          {/*       {...(formik.touched.seasons && formik.errors.seasons ? { isError: true, captionText: formik.errors.seasons } : {})} */}
          {/*     /> */}
          {/*   </div> */}
          {/* </fieldset> */}

          <fieldset>
            <legend className={styles.form__legend}>
              All Users{' '}
              {help.find(
                (h) => h.tag === 'my_camps_camp_details_name_users'
              ) && (
                <HelpModal
                  help={help.find(
                    (h) => h.tag === 'my_camps_camp_details_name_users'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'my_camps_camp_details_name_users'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </legend>
            <div className={clsx(styles.form__row, styles.roles__wrapper)}>
              {_.concat(
                [
                  {
                    name: user.name,
                    role: formik.values.roles.find(
                      (role) => role.userId === user.id
                    )?.role,
                    email: '',
                  },
                ],
                formik.values.roles.filter((role) => role.userId !== user.id)
              ).map((r, rIndex) => (
                <div
                  className={clsx(styles.form__col, styles.role)}
                  key={rIndex}
                >
                  <span className={styles.role__no}>{rIndex + 1}</span>
                  <span className={styles.role__name}>{r.name}</span>
                  <span className={styles.role__text}>{r.role}</span>
                  {r.email ? (
                    <p className={styles.role__email}>{r.email}</p>
                  ) : null}
                  {rIndex && r.role !== 'OWNER' ? (
                    <button
                      className={styles.role__remove}
                      onClick={(e) => {
                        e.preventDefault()
                        handleRemoveRole(r, rIndex)
                      }}
                    >
                      <RemoveIcon />
                    </button>
                  ) : null}
                </div>
              ))}
            </div>

            <div
              className={clsx(styles.form__row, styles.role__add_role__wrapper)}
            >
              <TertiaryButton
                className={styles.role__add_role}
                onClick={() => setOpenAddRole(true)}
              >
                <PlusCircleIcon />
                Add Role
              </TertiaryButton>
            </div>
          </fieldset>

          <fieldset>
            <legend className={styles.form__legend_help}>
              Camp CSV Files
              <div>
                <div className={styles.form__header__desc}>
                  <a
                    className={styles.form__sample_csv}
                    href={SampleCsv}
                    target="customers.csv"
                  >
                    Download Sample CSV
                  </a>
                  {help.find(
                    (h) => h.tag === 'my_camps_camp_details_csv_files'
                  ) && (
                    <HelpModal
                      help={help.find(
                        (h) => h.tag === 'my_camps_camp_details_csv_files'
                      )}
                    >
                      <>
                        <p>
                          {
                            help.find(
                              (h) => h.tag === 'my_camps_camp_details_csv_files'
                            )?.text
                          }
                        </p>
                        <ol>
                          <li>Download Sample CSV file.</li>
                          <li>
                            Copy data into the existing columns (keep existing
                            headings, do not add spaces or punctuation).
                          </li>
                          <li>
                            Do not make edits to this file or it will not save
                            properly.
                          </li>
                          <li>
                            You can change the file name when saving. but it
                            must be saved in the format downloaded (i.e. CSV
                            UTF-8 (Comma delimited) (*.csv)
                          </li>
                          <li>
                            Upload the same file you downloaded but now
                            populated with your data.
                          </li>
                        </ol>
                      </>
                    </HelpModal>
                  )}
                </div>
              </div>
            </legend>

            <div className={styles.csv_file__row}>
              <input
                ref={customerCsvFileRef}
                type="file"
                accept=".csv"
                hidden
                onChange={handleAddCustomerCsvFile}
              />
              Master Camp List{' '}
              <button
                type="button"
                onClick={handleClickCustomerCsvFileUploader}
              >
                <PlusCircleIcon />
              </button>
            </div>

            {formik.values.customerCsvFiles
              .filter((csvFile) => csvFile)
              .map((csvFile, index) => (
                <div
                  key={index}
                  className={clsx(styles.csv_file__row, styles.csv_file)}
                >
                  <span>
                    <CSVIcon />
                    {csvFile}
                  </span>
                  <button
                    type="button"
                    onClick={() => handleRemoveCustomerCsvFile(csvFile)}
                  >
                    <RemoveIcon />
                  </button>
                </div>
              ))}

            <div className={styles.csv_file__row}>
              <input
                ref={counselorCsvFileRef}
                type="file"
                accept=".csv"
                hidden
                onChange={handleAddCounselorCsvFile}
              />
              Alumni List{' '}
              <button
                type="button"
                onClick={handleClickCounselorCsvFileUploader}
              >
                <PlusCircleIcon />
              </button>
            </div>

            {formik.values.counselorCsvFiles
              .filter((csvFile) => csvFile)
              .map((csvFile, index) => (
                <div
                  key={index}
                  className={clsx(styles.csv_file__row, styles.csv_file)}
                >
                  <span>
                    <CSVIcon />
                    {csvFile}
                  </span>
                  <button
                    type="button"
                    onClick={() => handleRemoveCounselorCsvFile(csvFile)}
                  >
                    <RemoveIcon />
                  </button>
                </div>
              ))}
          </fieldset>

          <div className={styles.form__buttons}>
            <SecondaryButton
              className={styles.form__cancel}
              label="Cancel"
              onClick={history.goBack}
            />

            <PrimaryButton
              className={styles.form__save}
              label="Save"
              onClick={formik.handleSubmit}
            />
          </div>
        </form>
      </div>

      <ColorPickerModal
        show={openColorPicker}
        onClose={() => setOpenColorPicker(false)}
        options={colorPickerOptions}
      />

      <AddRoleModal
        show={openAddRole}
        onClose={() => setOpenAddRole(false)}
        onAdd={(role) => handleAddRole(role)}
      />
    </>
  )
}

export default Edit
